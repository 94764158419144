<template>
	<div>
		<div class="header-background">
			<el-carousel
				v-if="swiperList.length > 0"
				:height="carouselHeight + 'px'"
			>
				<el-carousel-item
					v-for="item in swiperList"
					:key="item.id"
					class="swiper-item"
					:style="{ backgroundImage: `url(${item.picPath})` }"
				>
				</el-carousel-item>
			</el-carousel>
			<img
				v-else
				src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625643239478.png"
				alt=""
			/>
		</div>

		<div class="section w">
			<div class="company-profile">
				<div v-if="companyProfile" class="company-profile-title">公司简介</div>
				<div v-if="companyProfile" class="company-profile-desc">
					{{ companyProfile }}
				</div>
				<ul class="company-business">
					<li>
						<img
							src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625651470982.png"
							alt=""
						/>
						<span>线上社交</span>
					</li>
					<li>
						<img
							src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625651492057.png"
							alt=""
						/>
						<span>旅行产品</span>
					</li>
					<li>
						<img
							src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625651516641.png"
							alt=""
						/>
						<span>软件开发</span>
					</li>
				</ul>
				<div class="about-us">
					<div class="about-us-left">
						<div class="about-us-left-title">关于我们</div>
						<div class="about-us-left-content">
							<span> 名称：墨鱼旅行</span>
							<span> 亮点：和有趣的人组队看世界 </span>
							<span> 价值：为全球驴友匹配契合的队友 </span>
							<span> 使命：打造全球最大的旅行社交平台 </span>
							<span> 愿景：不止于旅行跟世界交朋友</span>
							<span> 特色：万能的队长、有趣的驴友、创意的行程</span>
						</div>
					</div>
					<div v-if="!isMobile" class="about-us-right">
						<img
							src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626252229378.png"
							alt=""
						/>
					</div>
				</div>
			</div>
			<div v-if="latestNewsVisible" class="latest-news">
				<div
					class="prev-btn"
					@click="
						imgMovePrev(
							'latestNewsImgs',
							'latestNewsImgsContainer',
							'prevBtn',
							'nextBtn',
							408,
							3
						)
					"
					ref="prevBtn"
				>
					<img
						src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png"
						alt=""
					/>
				</div>
				<div
					class="next-btn"
					@click="
						imgMoveNext(
							'latestNewsImgs',
							'latestNewsImgsContainer',
							'prevBtn',
							'nextBtn',
							408,
							3
						)
					"
					ref="nextBtn"
				>
					<img
						src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png"
						alt=""
					/>
				</div>
				<div class="latest-news-title">最新资讯</div>
				<div class="latest-news-imgs-container" ref="latestNewsImgsContainer">
					<div class="latest-news-imgs" ref="latestNewsImgs">
						<!-- for循环此处 -->
						<div
							v-for="item in latestNewsList"
							:key="item.id"
							class="latest-news-img"
							@click="jumpLatestNews(item)"
							:style="{ backgroundImage: 'url(' + item.picPath + ')' }"
						>
							<div class="latest-news-img-title">{{ item.title }}</div>
							<div class="latest-news-img-content">{{ item.content }}</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="tourArticleVisible" class="travel-artcle">
				<div class="travel-artcle-title">旅行文章</div>
				<div class="travel-artcle-imgs">
					<div class="travel-artcle-imgs-box">
						<div class="travel-artcle-left">
							<div class="date">
								<div class="date-left">{{ date }}</div>
								<div class="date-right">{{ monthList[month] }}.</div>
							</div>
							<div class="travel-artcle-left-bottom">
								<div class="travel-artcle-left-title">{{ site.name }}</div>
								<div class="travel-artcle-left-content">
									{{ site.description }}
								</div>
							</div>
						</div>
						<div
							class="travel-artcle-right-container"
							ref="travelArtcleRightContainer"
						>
							<div class="travel-artcle-right" ref="travelArtcleRight">
								<!-- for循环此处 -->
								<div
									v-for="item in tourArticleList"
									:key="item.id"
									class="travel-artcle-right-img"
									@click="jumpLatestNews(item)"
								>
									<div
										class="travel-artcle-right-img-top"
										:style="{ backgroundImage: 'url(' + item.picPath + ')' }"
									></div>
									<div class="travel-artcle-right-img-bottom">
										<div class="travel-artcle-right-img-bottom-title">
											{{ item.title }}
										</div>
										<div class="travel-artcle-right-img-bottom-content">
											{{ item.content }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="prev-btn"
						@click="
							imgMovePrev(
								'travelArtcleRight',
								'travelArtcleRightContainer',
								'prevBtn1',
								'nextBtn1',
								236,
								4
							)
						"
						ref="prevBtn1"
					>
						<img
							src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png"
							alt=""
						/>
					</div>
					<div
						class="next-btn"
						@click="
							imgMoveNext(
								'travelArtcleRight',
								'travelArtcleRightContainer',
								'prevBtn1',
								'nextBtn1',
								236,
								4
							)
						"
						ref="nextBtn1"
					>
						<img
							src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { articleList, articleTagList } from "@/http/api";
	export default {
		data() {
			return {
				companyProfile: "",
				latestNewsImgsMoving: true,
				travelArtcleImgMoving: true,
				latestNewsVisible: true,
				tourArticleVisible: true,
				swiperList: [],
				latestNewsList: [],
				site: {},
				tourArticleList: [],
				date: new Date().getDate(),
				// 数组索引从0开始, 所以不加一
				month: new Date().getMonth(),
				monthList: [
					"Jan",
					"Feb",
					"Mar",
					"Apr",
					"May",
					"Jun",
					"Jul",
					"Aug",
					"Spt",
					"Oct",
					"Nov",
					"Dec",
				],
				isMobile: this.$isMobile,
				carouselHeight: 720,
			};
		},
		created() {
			this.getCompanyProfile();
			this.getArticleList("website_information");
			this.getArticleList("website_tour_article");
			this.getSwiperList();
		},
		mounted() {
			this.$refs.latestNewsImgs.addEventListener(
				"transitionrun",
				function () {
					this.latestNewsImgsMoving = false;
				}.bind(this)
			);
			this.$refs.latestNewsImgs.addEventListener(
				"transitionend",
				function () {
					this.latestNewsImgsMoving = true;
				}.bind(this)
			);
			this.$refs.travelArtcleRight.addEventListener(
				"transitionrun",
				function () {
					this.travelArtcleImgMoving = false;
				}.bind(this)
			);
			this.$refs.travelArtcleRight.addEventListener(
				"transitionend",
				function () {
					this.travelArtcleImgMoving = true;
				}.bind(this)
			);
		},
		methods: {
			// 获取公司简介
			async getCompanyProfile() {
				const { data: res } = await articleTagList({
					nid: "company_profile",
				});
				if (res.code === 200 && res.data.length > 0) {
					this.companyProfile = res.data[0].content;
				}
			},
			async getArticleList(nid) {
				const { data } = await articleList({ nid: nid });
				if (data.code === 200) {
					let list = [];
					list = data.data.articles;
					list.forEach((item) => {
						item.picPath = this.$oss + item.picPath;
					});

					if (nid === "website_tour_article") {
						this.site = data.data.site;
					}
					if (nid === "website_information") {
						if (list.length > 3) {
							this.$refs.nextBtn.style.display = "block";
						} else if (list.length === 0) {
							this.latestNewsVisible = false;
						}
						this.latestNewsList = list;
					} else if (nid === "website_tour_article") {
						if (list.length > 3) {
							this.$refs.nextBtn1.style.display = "block";
						} else if (list.length === 0) {
							this.tourArticleVisible = false;
						}
						this.tourArticleList = list;
					}
				} else {
					if (nid === "website_information") {
						this.latestNewsVisible = false;
					} else if (nid === "website_tour_article") {
						this.tourArticleVisible = false;
					}
				}
			},
			async getSwiperList() {
				const { data } = await articleList({ nid: "os_home_swiper" });

				if (data.code === 200) {
					data.data.articles.forEach((item) => {
						item.picPath = this.$oss + item.picPath;
					});
					this.swiperList = data.data.articles;
					this.imgLoad();
				}
			},
			imgMoveNext(moveBox, container, prevBtn, nextBtn, itemWidth, imgSize) {
				// imgSize: 页面中显示的张数
				if (!this.latestNewsImgsMoving) {
					return;
				}
				const pBtn = this.$refs[prevBtn];
				const nBtn = this.$refs[nextBtn];
				const imgMoveBox = this.$refs[moveBox];
				const imgContainer = this.$refs[container].offsetWidth;
				const maxLength =
					imgMoveBox.children[imgMoveBox.children.length - 1].offsetLeft;
				if (Math.abs(imgMoveBox.offsetLeft) >= maxLength - imgContainer) {
					return;
				}
				imgMoveBox.style.left = imgMoveBox.offsetLeft + -itemWidth + "px";
				// 隐藏右侧按钮
				if (
					imgMoveBox.children.length * itemWidth ===
					Math.abs(imgMoveBox.offsetLeft - itemWidth * (imgSize + 1))
				) {
					nBtn.style.display = "none";
				} else {
					nBtn.style.display = "block";
				}
				// 隐藏左侧按钮
				if (Math.abs(imgMoveBox.offsetLeft) >= 0) {
					pBtn.style.display = "block";
				} else {
					pBtn.style.display = "none";
				}
			},

			imgMovePrev(moveBox, container, prevBtn, nextBtn, itemWidth, imgSize) {
				if (!this.latestNewsImgsMoving) {
					return;
				}
				const pBtn = this.$refs[prevBtn];
				const nBtn = this.$refs[nextBtn];
				const imgMoveBox = this.$refs[moveBox];
				if (Math.abs(imgMoveBox.offsetLeft) <= 0) {
					return;
				}
				imgMoveBox.style.left = imgMoveBox.offsetLeft + itemWidth + "px";
				// 隐藏右侧按钮
				if (
					imgMoveBox.children.length * itemWidth ===
					Math.abs(imgMoveBox.offsetLeft - itemWidth * imgSize + 1)
				) {
					nBtn.style.display = "none";
				} else {
					nBtn.style.display = "block";
				}
				// 隐藏左侧按钮
				if (Math.abs(imgMoveBox.offsetLeft) <= itemWidth) {
					pBtn.style.display = "none";
				} else {
					pBtn.style.display = "block";
				}
			},

			jumpLatestNews(params) {
				this.$router.push({
					name: "latestNews",
					query: params,
				});
			},
			// 获取图片的实际长度和宽度
			getImgAttr(url, callback) {
				let img = new Image();
				img.src = url;
				if (img.complete) {
					callback(img.width, img.height);
					// console.log(img.width, img.height, 'img.width, img.height')
				} else {
					img.onload = function () {
						callback(img.width, img.height);
						img.onload = null;
					};
				}
			},
			imgLoad() {
				this.$nextTick(function () {
					// 定义页面初始的走马灯高度, 默认以第一张图片高度
					console.log(this.swiperList);
					if (this.swiperList.length) {
						this.getImgAttr(this.swiperList[0].picPath, (width, height) => {
							// 获取屏宽计算图片容器高度
							let screenWidth =
								window.innerWidth ||
								document.documentElement.clientWidth ||
								document.body.clientWidth;
							this.carouselHeight = (height / width) * screenWidth;
              console.log(this.carouselHeight)
						});
					}
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.header-background {
		// width: 100%;
		// height: 720px;
		.el-carousel {
			width: 100%;
			height: 100%;
			.swiper-item {
				width: 100%;
				height: 100%;
				background-size: 100% 100%;
			}
		}
	}
	.section {
		margin-top: 88px;
		.company-profile {
			.company-profile-title {
				text-align: center;
				margin-bottom: 58px;
				font-size: 28px;
				font-family: PingFang-SC-Heavy, PingFang-SC;
				font-weight: 500;
				color: #333333;
			}
			.company-profile-desc {
				line-height: 32px;
				font-size: 22px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #666666;
			}
			.company-business {
				display: flex;
				justify-content: space-around;
				margin: 58px 0 88px;
				li {
					display: flex;
					flex-direction: column;
					align-items: center;
					img {
						width: 120px;
						height: 120px;
						margin-bottom: 24px;
					}
					span {
						font-size: 18px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 600;
						color: #333333;
					}
				}
			}
			.about-us {
				display: flex;
				height: 356px;
				border-radius: 18px;
				.about-us-left {
					display: flex;
					flex-direction: column;
					align-items: center;
					flex: 50%;
					background-color: #063b63;

					.about-us-left-title {
						margin: 51px 0 28px;
						font-size: 22px;
						font-family: PingFang-SC-Heavy, PingFang-SC;
						font-weight: 800;
						color: #ffffff;
					}
					.about-us-left-content {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						width: 88%;
						line-height: 28px;
						font-size: 16px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #ffffff;
					}
				}
				.about-us-right {
					flex: 50%;
				}
			}
		}
		.latest-news {
			position: relative;
			width: 100%;
			margin-top: 88px;
			height: 350px;
			.latest-news-imgs-container {
				overflow: hidden;
				position: relative;
				width: 100%;
				height: 252px;
				.latest-news-imgs {
					position: absolute;
					left: 0;
					width: 500%;
					height: 252px;
					// 图片移动动画
					transition: all 1s;

					.latest-news-img {
						float: left;
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
						align-items: center;
						margin-right: 24px;
						width: 384px;
						height: 252px;
						border-radius: 18px;
						cursor: pointer;
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;
						&:last-child {
							margin-right: 0;
						}
						.latest-news-img-title {
							align-self: flex-start;
							margin-left: 20px;
							font-size: 16px;
							font-family: PingFang-SC-Bold, PingFang-SC;
							font-weight: bold;
							color: #ffffff;
							width: 344px;
							//强制一行显示
							white-space: nowrap;
							overflow: hidden;
							//溢出的文字用省略号表示
							text-overflow: ellipsis;
						}
						.latest-news-img-content {
							margin: 10px 0 20px;
							width: 344px;
							height: 40px;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #ffffff;
							line-height: 20px;
							overflow: hidden;
							text-overflow: ellipsis;
							// 弹性伸缩盒子模型显示
							display: -webkit-box;
							// 限制在一个块元素显示的文本的行数
							-webkit-line-clamp: 2;
							// 设置或检索伸缩盒对象的子元素的排列方式
							-webkit-box-orient: vertical;
						}
					}
				}
			}
			.prev-btn {
				display: none;
				position: absolute;
				top: 50%;
				left: -34px;
				z-index: 999;
				width: 68px;
				height: 68px;
				transform: rotate(180deg);
				cursor: pointer;
			}
			.next-btn {
				display: none;
				position: absolute;
				top: 50%;
				right: -34px;
				z-index: 999;
				width: 68px;
				height: 68px;
				cursor: pointer;
			}
			.latest-news-title {
				margin-bottom: 58px;
				text-align: center;
				font-size: 28px;
				font-family: PingFang-SC-Heavy, PingFang-SC;
				font-weight: 800;
				color: #333333;
			}
		}
		.travel-artcle {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			margin-top: 88px;
			height: 488px;
			.travel-artcle-title {
				font-size: 28px;
				font-family: PingFang-SC-Heavy, PingFang-SC;
				font-weight: 800;
				color: #333333;
			}
			.travel-artcle-imgs {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 390px;
				border-radius: 18px;
				background: url(https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626252330861.png);
				.travel-artcle-imgs-box {
					display: flex;
					width: 1126px;
					height: 292px;
					.travel-artcle-left {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						width: 190px;
						height: 100%;
						.date {
							display: flex;
							align-items: flex-end;
							.date-left {
								margin-right: 6px;
								font-size: 52px;
								font-family: PingFangSC-Medium, PingFang SC;
								font-weight: 500;
								color: #ffffff;
							}
							.date-right {
								font-size: 22px;
								font-family: PingFangSC-Medium, PingFang SC;
								font-weight: 500;
								color: #ffffff;
							}
						}
						.travel-artcle-left-bottom {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							height: 62px;
							.travel-artcle-left-title {
								font-size: 18px;
								font-family: PingFang-SC-Bold, PingFang-SC;
								font-weight: bold;
								color: #ffffff;
							}
							.travel-artcle-left-content {
								font-size: 12px;
								font-family: PingFang-SC-Medium, PingFang-SC;
								font-weight: 500;
								color: #ffffff;
							}
						}
					}
					.travel-artcle-right-container {
						overflow: hidden;
						position: relative;
						width: 100%;
						margin-left: 24px;
					}
					.travel-artcle-right {
						width: 500%;
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						transition: all 1s;
						.travel-artcle-right-img {
							overflow: hidden;
							float: left;
							margin-right: 8px;
							border-radius: 10px;
							width: 228px;
							height: 100%;
							background-color: #fff;
							cursor: pointer;
							&:last-child {
								margin-right: 0;
							}
							.travel-artcle-right-img-top {
								height: 186px;
								background-repeat: no-repeat;
								background-size: cover;
								background-position: center center;
							}
							.travel-artcle-right-img-bottom {
								display: flex;
								flex-direction: column;
								justify-content: space-around;
								align-items: center;
								height: 106px;
								.travel-artcle-right-img-bottom-title {
									width: 200px;
									height: 48px;
									font-size: 16px;
									font-family: PingFangSC-Medium, PingFang SC;
									font-weight: 500;
									color: #333333;
									line-height: 24px;
									overflow: hidden;
									text-overflow: ellipsis;
									// 弹性伸缩盒子模型显示
									display: -webkit-box;
									// 限制在一个块元素显示的文本的行数
									-webkit-line-clamp: 2;
									// 设置或检索伸缩盒对象的子元素的排列方式
									-webkit-box-orient: vertical;
								}
								.travel-artcle-right-img-bottom-content {
									text-align: right;
									align-self: flex-end;
									margin-right: 14px;
									width: 200px;
									font-size: 12px;
									font-family: PingFangSC-Regular, PingFang SC;
									font-weight: 400;
									color: #999999;
									//强制一行显示
									white-space: nowrap;
									overflow: hidden;
									//溢出的文字用省略号表示
									text-overflow: ellipsis;
								}
							}
						}
					}
				}
				.prev-btn {
					display: none;
					position: absolute;
					top: calc(50% - 34px);
					left: -34px;
					z-index: 999;
					width: 68px;
					height: 68px;
					transform: rotate(180deg);
					cursor: pointer;
				}
				.next-btn {
					display: none;
					position: absolute;
					top: calc(50% - 34px);
					right: -34px;
					width: 68px;
					height: 68px;
					cursor: pointer;
				}
			}
		}
	}
</style>